import React from 'react';
import './Entrance.css'
import flecha from '../componentes/flecha.png'
import { Link } from 'react-scroll';
const Entrance = () => {
    return (
        <div className='entrance'>
            <div className='entrance-text'>
        <h1>Bienvenidos a Globo</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus interdum blandit orci, sit amet lobortis elit consectetur nec. In eu libero dolor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut pretium ut neque nec malesuada. Cras viverra, diam ac pellentesque ultrices, nisi nunc lobortis nunc, id commodo nisi felis in augue. Duis vel aliquam nunc. Proin vitae faucibus nibh. In molestie arcu eu orci porttitor, a commodo mi luctus. Cras in laoreet est. Pellentesque lacinia turpis quam, auctor sagittis risus ornare sit amet. Donec consectetur mi libero, sed pretium felis blandit ut. Sed non odio est. Vestibulum luctus odio ac lorem vehicula, ut congue lacus euismod.</p>
                <Link to='specialities' smooth={true} offset={-320} duration={500} className='btn'>Mira nuestras Especialidades<img src={flecha} alt='flecha'></img></Link>
            </div>
        </div>
    )
}
export default Entrance