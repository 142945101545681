import './App.css';
import Header from './componentes/Header';
import Entrance from './paginas/Entrance';
import Menus from './paginas/Menus';
import Specialities from './paginas/Specialities';
import Title from './componentes/Title';
import GroupMenu from './paginas/GroupMenu';
import Reservation from './paginas/Reservation';
import Footer from './componentes/Footer';
import Cookies from './componentes/Cookies';
function App() {
  return (
    <div>
      <Header></Header>
      <Entrance></Entrance>
      <Cookies></Cookies>
      <Menus></Menus>
      <Title title="¡Prueba Nuestras Globo Especialidades!" subTitle="En Globo comerás como en casa."></Title>
      <Specialities></Specialities>
      <Title title="Disfruta de nuestros menús para grupos" subTitle="Aquí hay espacio para todos"></Title>
      <GroupMenu></GroupMenu>
      <Title title="¡Reservas abiertas!" subTitle="Disfruta con Globo"></Title>
      <Reservation></Reservation>
      <Footer></Footer>
    </div>
  );
}

export default App;
