import React, { useRef, useState, useEffect } from 'react'
import '/Users/Hyena/restaurante/src/paginas/GroupMenu.css'
import back_icon from '../componentes/back-icon.png'
import next_icon from '../componentes/next-icon.png'
import vegan from '../componentes/Pngtree vegan friendly label png_8367839.png'
import birth from '../componentes/Pngtree happy birthday png transparent clipart_6555382.png'
import random from '../componentes/PNG_transparency_demonstration_1.png'
import buffet from '../componentes/Food-Buffet-PNG-File.png'
import flecha from '../componentes/flecha.png'
import { Link } from 'react-scroll'
const GroupMenu = () => {
  
  const slider = useRef(null);
  const [tx, setTx] = useState(0);
  const slideForward = () => {
    if (tx > -75) {  
      setTx(tx - 25);
    }
  };

  const slideBackward = () => {
    if (tx < 0) {
      setTx(tx + 25);
    }
  };

  useEffect(() => {
    slider.current.style.transform = `translateX(${tx}%)`;
  }, [tx]);

  return (
    <div className='groupmenu'>
      <img src={next_icon} alt='' className='next-btn' onClick={slideForward}></img>
      <img src= {back_icon} alt='' className='back-btn' onClick={slideBackward}></img>
      <div className='slider'>
        <ul ref={slider}>
          <li>
            <div className='slide'>
              <div className='menug-info'>
                <img src={vegan} alt=''></img>
                <h3>Menú vegano</h3>
                <span>Grupo 1</span>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus interdum blandit orci, sit amet lobortis elit consectetur nec. In eu libero dolor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut pretium ut neque nec malesuada. Cras viverra, diam ac pellentesque ultrices, nisi nunc lobortis nunc, id commodo nisi felis in augue. Duis vel aliquam nunc. Proin vitae faucibus nibh. In molestie arcu eu orci porttitor, a commodo mi luctus. Cras in laoreet est. Pellentesque lacinia turpis quam, auctor sagittis risus ornare sit amet.</p>
            </div>
          </li>
          <li>
            <div className='slide'>
              <div className='menug-info'>
                <img src={birth} alt=''></img>
                <h3>Menú Cumpleaños</h3>
                <span>Grupo 2</span>
              </div>
              <p>Nulla vel risus ac dolor finibus sagittis. Vestibulum auctor mauris nec enim consequat, quis varius elit accumsan. Quisque ornare blandit orci non euismod. Aliquam pulvinar nisl sed enim ultrices, at accumsan ex vestibulum. In lobortis placerat sem non pretium. Phasellus bibendum scelerisque iaculis. Mauris cursus dui dui, non vestibulum diam vehicula quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at sagittis ante.</p>
            </div>
          </li>
          <li>
            <div className='slide'>
              <div className='menug-info'>
                <img src={random} alt=''></img>
                <h3>Menú Sorpresa</h3>
                <span>Grupo 3</span>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus interdum blandit orci, sit amet lobortis elit consectetur nec. In eu libero dolor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut pretium ut neque nec malesuada. Cras viverra, diam ac pellentesque ultrices, nisi nunc lobortis nunc, id commodo nisi felis in augue. Duis vel aliquam nunc. Proin vitae faucibus nibh. In molestie arcu eu orci porttitor, a commodo mi luctus. Cras in laoreet est. Pellentesque lacinia turpis quam, auctor sagittis risus ornare sit amet.</p>
            </div>
          </li>
          <li>
            <div className='slide'>
              <div className='menug-info'>
                <img src={buffet} alt=''></img>
                <h3>Buffet Libre</h3>
                <span>Grupo 4</span>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus interdum blandit orci, sit amet lobortis elit consectetur nec. In eu libero dolor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut pretium ut neque nec malesuada. Cras viverra, diam ac pellentesque ultrices, nisi nunc lobortis nunc, id commodo nisi felis in augue. Duis vel aliquam nunc. Proin vitae faucibus nibh. In molestie arcu eu orci porttitor, a commodo mi luctus. Cras in laoreet est. Pellentesque lacinia turpis quam, auctor sagittis risus ornare sit amet.</p>
            </div>
          </li>
        </ul>
      </div>
      <Link to='reserva' smooth={true} offset={-380} duration={500} className='btn-reserva'>Reserva con Nosotros aquí<img src={flecha} alt='flecha'></img></Link>
    </div>
    
  )
}

export default GroupMenu
