import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div className='footer'>
          <p>© 2024 GloboEats. All rights reserved.</p>
      <ul>
        <li><a href={'https://www.modelosycontratos.com/modelo-aviso-legal-pagina-web/'}>Términos de Servicio</a></li>
        <li><a href={'https://www.aepd.es/politica-de-privacidad-y-aviso-legal'}>Política de Privacidad</a></li>
      </ul>
    </div>
  )
}

export default Footer
