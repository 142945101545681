import React from 'react'
import CookieConsent from 'react-cookie-consent'
const Cookies = () => {
   return (
    <CookieConsent
      location="bottom"
      buttonText="Aceptar"
      declineButtonText="Rechazar"
      cookieName="miCookiePersonalizada"
       style={{ background: "#591902" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
      enableDeclineButton
      onAccept={(acceptedByScrolling) => {
        if (acceptedByScrolling) {
          alert("La aceptación fue activada por el desplazamiento del usuario"); /*Revisar legalidad hay algunos países en los que este método es ilegal como por ejemplo Italia*/
        } else {
          alert("La aceptación fue activada al hacer clic en el botón de Aceptar");
        }
      }}
      onDecline={() => {
        alert("¡Rechazado!");
      }}
    >
       Utilizamos cookies propias y de terceros para obtener datos estadísticos de la navegación de nuestros usuarios y mejorar nuestros servicios. Si acepta o continúa navegando, consideramos que acepta su uso. Puede cambiar la configuración u obtener más información aquí{" "}
       <span style={{ fontSize: "10px" }}><a style={{ color: "#F28705"}} href={"https://www.modelosycontratos.com/modelo-aviso-cookies-pagina-web/"}>Saber más.</a></span>
    </CookieConsent>
  );
  
}

export default Cookies
