import React, { useState } from 'react';
import './Header.css';
import menu_icon from './menu-icon.png';
import Globo from './Globo.png';
import { Link } from 'react-scroll';
const Header =() => {
    const [mobileMenu, setMobileMenu] = useState(false)
    const toggleMenu = ()=> {
        mobileMenu ? setMobileMenu(false) : setMobileMenu(true)
    }
    return (
        <nav className="navbar">
        <div className='navbar-logo-container'>
                <a href={hrefValue.toString()} className='navbar-item'>
                <img src={Globo} alt='logo' className='logo-img'></img>
            </a>
                <ul className={mobileMenu?'': 'hide-mobile-menu'}>
                <li><Link to='menu' smooth={true} offset={-140} duration={500}>Carta</Link></li>
                    <li><Link to='specialities' smooth={true} offset={-320} duration={500}>Especialidades</Link></li>
                    <li><Link to='groupmenu' smooth={true} offset={-350} duration={500}>Menú Grupos</Link></li>
                    <li><Link to='reserva' smooth={true} offset={-380} duration={500}>Reservas</Link></li>
            </ul>
            
        </div>
            <img src={menu_icon} alt='' className='menu-icon' onClick={toggleMenu}></img>
            </nav>
    )

}
const isHomePage = true;
const hrefValue = isHomePage ? "/" : "/about";
export default Header;
