import React from 'react'
import './Menus.css'
const Menus = () => {
  return (
    <div className='menu'>
          <div className="menu-text">
              <h2>GloboMenú</h2>
              <div className="platos">
                <h3>Bocado de Lubina</h3>
                <p>Una porción de Lubina adobada y horneada acompañada de una selección exquisita de verduras y un vino seleccionado para la ocasión.</p>
                <p>Precio: 12.99</p>
                </div>
              <div className="platos">
                  <h3>Albóndigas  Caseras</h3>
                  <p>Un buen plato de Albóndigas de carne mixta acompañadas de guisantes y patatas y con el guiso secreto de la casa.</p>
                  <p>Precio: 14.99</p>
              </div>
              <div className="platos">
                  <h3>Ensalada Parmesana</h3>
                  <p>Ensalada hecha con la mejor selección de verduras, rucula y aceite de oliva, acompañada con Salmón y el mejor queso parmesano.</p>
                  <p>Precio: 13.99</p>
              </div>
              <div className="platos">
                  <h3>Chuletón</h3>
                  <p>La mejor carne de res que podemos proporcinar junto a una guarnición de patatas.</p>
                  <p>Precio: 16.99</p>
              </div>
              <div className="platos">
                  <h3>Takoyaki</h3>
                  <p>Desde japón traemos una de nuestras especialidades hechas con pulpo.</p>
                  <p>Precio: 12.99</p>
              </div>
              <div className="platos">
                  <h3>Tarta de limón</h3>
                  <p>El postre estrella de la casa.</p>
                  <p>Precio: 10.99</p>
                </div>  
                  <div className="platos">
                      <h3>Tortitas</h3>
                      <p>Desde Estados Unidos traemos la mejor receta de Tortitas con un buen acompañamiento de sirope y plátano.</p>
                      <p>Precio: 11.99</p>
                  </div>
                  <div className="platos">
                      <h3>Tarta de Frambuesa</h3>
                      <p>El Nuevo lanzamiento de Globo para disfrute del público.</p>
                      <p>Precio: 12.99</p>
                  </div>
             <aside className='menu-llevar'>Platos para llevar
          <div className="platos">
            <h3>Takoyaki</h3>
            <p>Desde japón traemos una de nuestras especialidades hechas con pulpo.</p>
            <p>Precio: 14.99</p>
          </div>
          <div className="platos">
            <h3>Albóndigas  Caseras</h3>
            <p>Un buen plato de Albóndigas de carne mixta acompañadas de guisantes y patatas y con el guiso secreto de la casa.</p>
            <p>Precio: 16.99</p>
          </div>
          <div className="platos">
            <h3>Tarta de limón</h3>
            <p>El postre estrella de la casa.</p>
            <p>Precio: 12.99</p>
          </div> 
          <div className="platos">
            <h3>Tarta de Frambuesa</h3>
            <p>El Nuevo lanzamiento de Globo para disfrute del público.</p>
            <p>Precio: 14.99</p>
          </div>
             </aside>
          </div>
    </div>
  )
}

export default Menus
