import React from 'react'
import './Specialities.css'
import Special_1 from '../componentes/mesh-LipkIP4fXbM-unsplash recortada.jpg'
import Special_2 from '../componentes/big-dodzy-Re5kf-8o3eM-unsplash.jpg'
import Special_3 from '../componentes/chad-montano-eeqbbemH9-c-unsplash.jpg'
import Special_4 from '../componentes/anna-tukhfatullina-food-photographer-stylist-Mzy-OjtCI70-unsplash.jpg'
const Specialities = () => {
  return (
    <div className='specialities'>
      <div className='specialitie'>
        <img src={Special_1} alt=''></img>
        <div className='caption'><h1>Takoyaki</h1>
          <p>Hecha a base de harina de trigo y pulpo. Se hace en forma de una bola. Sus ingredientes son: masa de harina de trigo con agua, trozos de pulpo, jengibre encurtido troceado, alga, etc. Es típico de la región de Kansai.</p></div>
        </div>
    <div className='specialitie'>
        <img src={Special_2} alt=''></img>
        <div className='caption'><h1>Tarta de Limón</h1>
          <p>La tarta de limón es una tarta formada por una base de hojaldre que se rellena con crema de limón, se complementa con merengue de la casa.</p></div>
        </div>
          <div className='specialitie'>
        <img src={Special_3} alt=''></img>
        <div className='caption'><h1>Tortitas</h1>
          <p>Las clásicas tortitas son muy famosas como desayuno en EEUU. Los pancakes, pancaques o panqueques, varios nombres para el mismo tipo de tortita, una mezcla de leche, harina y levadura que se mezcla y a la plancha.</p></div>
        </div>
          <div className='specialitie'>
        <img src={Special_4} alt=''></img>
        <div className='caption'><h1>Tarta de Frambuesa</h1>
          <p>Núcleo gelificado de puré de frambuesa. Una fina capa de nata con un toque de dulce de leche, que se acomoda perfectamente a la ligera acidez de la fruta roja de bosque. Una mousse de frambuesa suave y espumosa que se deshace en la boca y un bizcocho blanco como base, que consigue la redondez de texturas y sabores de esta tarta. </p></div>
      </div>
    </div>
  )
}

export default Specialities
