import React from 'react'
import './Reservation.css'
import msg from '../componentes/msg-icon.png'
import location from '../componentes/location-icon.png'
import mail from '../componentes/mail-icon.png'
import phone from '../componentes/phone-icon.png'
import flechablanca from '../componentes/white-arrow.png'

const Reservation = () => {
  /* Añadir código de web3forms*/
  
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Enviando....");
    const formData = new FormData(event.target);

    formData.append("access_key", "cca19b53-3d78-4c35-9c33-9c3931c786aa");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Reserva enviada correctamente");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  
  return (
    <div className='reserva'>
      <div className='reserva-col'>
        <h3>Envíenos un Mensaje <img src={msg} alt=''></img></h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at ipsum elit. Donec tempor risus et mauris molestie rutrum vel sed justo. Nunc dignissim dapibus sapien, eget pellentesque velit euismod eu. Quisque semper justo a ligula pretium.</p>
              <ul>
          <li><img src={mail} alt=''></img> Contact@RestauranteGlobo.dev</li>
          <li><img src={phone} alt=''></img> +34 123-456-789</li>
          <li><img src={location} alt=''></img> Avenida Olímpica, Madrid, 28001, España</li>
              </ul>
      </div>
    <div className='reserva-col'>
    <form onSubmit={onSubmit}>
      <label>Tu Nombre</label>
      <input type='text' name='name' placeholder='Introduce tu nombre' required></input>
      <label>Número de Teléfono</label>
      <input type='tel' name='phone' placeholder='Introduce tu número de telefono' required></input>
      <label>Escribe tu mensaje aquí</label>
      <textarea name='message' rows="6" placeholder='Escribe tu mensaje' required></textarea>
      <label>Fecha para la Reserva</label>
      <input type='date' name='reserv-date' required></input>
      <button type='submit' className='btn'>Enviar <img src={flechablanca} alt=''></img></button>
    </form>
    <span>{result}</span>
     </div>
    </div>
  )
}

export default Reservation
